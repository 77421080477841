<!--
 * @Description: 积分获得设置
 * @Author: 琢磨先生
 * @Date: 2022-06-18 03:20:40
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-02 16:09:55
-->

<template>
  <el-card class="box">
    <div style="margin-bottom: 20px">积分获得设置</div>
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="活动开关">
        <span v-if="!visible">
          <el-tag type="success" v-if="form.score_enabled == '1'" size="small">已开启</el-tag>
          <el-tag type="danger" v-else size="small">关闭</el-tag>
          <span>开启后才能进行分积分获取、抵扣</span>
        </span>
        <el-switch
          v-if="visible"
          v-model="form.score_enabled"
          active-value="1"
          inactive-value="0"
        ></el-switch>
        <div class="help-block" v-if="visible">开启后才能进行积分获取、抵扣</div>
      </el-form-item>
      <el-form-item label="订房获得积分" prop="score_get_by_house">
        <el-col :span="5">
          <span v-if="!visible">订房消费1元即可获得{{ form.score_get_by_house }}积分</span>
          <el-input v-model="form.score_get_by_house" v-if="visible"></el-input>
        </el-col>
        <div class="help-block" v-if="visible">
          房源消费积分获得比例 ，例如：填写1时，
          则消费1元可获得1积分，2时消费1元可获得2积分
        </div>
      </el-form-item>
      <el-form-item label="商品获得积分" prop="score_get_by_goods">
        <el-col :span="5">
          <span v-if="!visible">商品消费1元即可获得{{ form.score_get_by_goods }}积分</span>
          <el-input v-model="form.score_get_by_goods" v-if="visible"></el-input>
        </el-col>
        <div class="help-block" v-if="visible">
          商品消费积分获得比例 ，例如：填写1时，
          则消费1元可获得1积分，2时消费1元可获得2积分
        </div>
      </el-form-item>

      <el-form-item label="积分抵扣金额" prop="score_equal_to_money">
        <el-col :span="5">
          <span v-if="!visible">1积分即可抵扣金额{{ form.score_equal_to_money }}元</span>
          <el-input v-model="form.score_equal_to_money" v-if="visible"></el-input>
        </el-col>
        <div class="help-block" v-if="visible">
          1积分可抵扣金额数，要求0~1，且小数不能超过3位，例如：0.01
          即1积分抵扣0.01元
        </div>
      </el-form-item>
      <el-form-item label="订房最大抵扣比例" prop="score_equal_to_money">
        <el-col :span="5">
          <span v-if="!visible">预定房间积分最大可抵扣房费比例{{ form.score_deduct_ratio_by_house }}%</span>
          <el-input v-model="form.score_deduct_ratio_by_house" v-if="visible">
            <template #suffix>%</template>
          </el-input>
        </el-col>
        <div class="help-block" v-if="visible">
          积分抵扣房费最大占比。例如房费1000元，积分抵扣比例5%，当1积分=1元时，用户最多可消耗50积分抵扣50元房费。
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" link @click="visible = true" size="small" v-if="!visible">修改</el-button>
        <el-button type="primary" @click="onSubmit" v-if="visible" :loading="saving">确定</el-button>
        <el-button @click="visible = false" v-if="visible" :disabled="saving">取消</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import common_api from "@/http/common_api";
export default {
  data() {
    return {
      visible: false,
      saving: false,
      form: {},
      settings: {},
      rules: {
        score_get_by_house: [
          { required: true, message: "请输入", trigger: "blur" },
          // { pattern: /^[1-9]\d*$/, message: "请输入整数", trigger: "blur" },
        ],
        score_get_by_goods: [
          { required: true, message: "请输入", trigger: "blur" },
          // { pattern: /^[1-9]\d*$/, message: "请输入整数", trigger: "blur" },
        ],
        score_equal_to_money: [
          { required: true, message: "请输入", trigger: "blur" },
          // {
          //   pattern: /^(0.\d{1,3}|0|1)$/,
          //   message: "请输入0-1之间的数字",
          //   trigger: "blur",
          // },
        ],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      common_api.get_settings().then((res) => {
        if (res.code == 0) {
          this.settings = res.data;
          this.form = Object.assign({}, this.settings);
        }
      });
    },
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/setting/save/score", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>